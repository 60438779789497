import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import ReportsIcon from "@mui/icons-material/PrintOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";

import {
  CategoryOutlined,
  ContactPageOutlined,
  DomainOutlined,
  StorageOutlined,
  FolderSharedOutlined,
  MediationOutlined,
  TypeSpecimenOutlined,
  TuneOutlined,
  RequestPageOutlined,
  RequestQuoteOutlined,
  LocationOnOutlined,
  GroupsOutlined,
  StoreOutlined,
  Inventory2Outlined,
  LocalShippingOutlined,
  ShoppingBagOutlined,
  AccessTimeOutlined,
  LocalMallOutlined,
} from "@mui/icons-material";
import i18n from "../../multilingual/i18n";
import { useConfigContext } from "../../../context/configcontext";
import { useAuthContext } from "../../../context/authcontext";

const MenuItems = (props: any) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { MODULES } = useConfigContext();
  const { userData } = useAuthContext();

  const [open, setOpen] = useState(props ? props.open : false);
  const [activeIndex, setActiveIndex] = useState(0);

  interface IMenuItem {
    id: number;
    text: string;
    icon: JSX.Element;
    link?: string | undefined;
    links?: IMenuItem[] | undefined;
  }

  const defaultMenuItems = [
    {
      id: 1,
      text: t("Dashboard"),
      icon: <InsertChartOutlinedIcon />,
      link: "/dashboard",
    },
    {
      id: 2,
      text: t("Leads"),
      icon: <RequestPageOutlined />,
      link: "/leads",
    },
    {
      id: 3,
      text: t("Quotations"),
      icon: <RequestQuoteOutlined />,
      link: "/quotations",
    },
    {
      id: 4,
      text: t("Inbound Requests"),
      icon: <SourceOutlinedIcon />,
      link: "/inboundrequests",
    },
    {
      id: 5,
      text: t("Outbound Requests"),
      icon: <SourceOutlinedIcon />,
      link: "/outboundrequests",
    },
    // {
    //   id: 6,
    //   text: t("Putaway Requests"),
    //   icon: <SourceOutlinedIcon />,
    //   link: "/putawayrequests",
    // },
    {
      id: 7,
      text: t("SKUs"),
      icon: <CategoryOutlined />,
      link: "/sku",
    },
    {
      id: 8,
      text: t("SKU Kits"),
      icon: <ShoppingBagOutlined />,
      link: "/skukit",
    },
    {
      id: 9,
      text: t("Customers"),
      icon: <GroupsOutlined />,
      link: "/customers",
    },
    {
      id: 12,
      text: t("Tenants"),
      icon: <DomainOutlined />,
      link: "/tenants",
    },
    {
      id: 10,
      text: t("Reports"),
      icon: <ReportsIcon />,
      links: [
        {
          id: 1001,
          text: t("SKU Report"),
          icon: <CategoryOutlined />,
          link: "/skureport",
        },
        {
          id: 1002,
          text: t("Inbound Reports"),
          icon: <ContactPageOutlined />,
          link: "/reports",
        },
        {
          id: 1003,
          text: t("Outbound Reports"),
          icon: <ListAltOutlinedIcon />,
          link: "/reports",
        },
      ],
    },
    {
      id: 11,
      text: t("Settings"),
      icon: <SettingsApplicationsOutlinedIcon />,
      links: [
        {
          id: 1101,
          text: t("Brands"),
          icon: <StoreOutlined />,
          link: "/brands",
        },
        {
          id: 1102,
          text: t("Packages"),
          icon: <ContactPageOutlined />,
          link: "/packages",
        },
        {
          id: 1103,
          text: t("Packing Materials"),
          icon: <Inventory2Outlined />,
          link: "/packingmaterials",
        },
        {
          id: 1104,
          text: t("SKU Category"),
          icon: <CategoryOutlined />,
          link: "/skucategory",
        },
        {
          id: 1105,
          text: t("Supplier"),
          icon: <LocalShippingOutlined />,
          link: "/supplier",
        },
        {
          id: 1106,
          text: t("Users"),
          icon: <ListAltOutlinedIcon />,
          link: "/users",
        },
        {
          id: 1107,
          text: t("Warehouses"),
          icon: <CategoryOutlined />,
          link: "/warehouses",
        },
        {
          id: 1108,
          text: t("Carriers"),
          icon: <LocalShippingOutlined />,
          link: "/warehousecarriers",
        },
        {
          id: 1109,
          text: t("Order Bins"),
          icon: <LocalMallOutlined />,
          link: "/warehouseorderbins",
        },
        {
          id: 1110,
          text: t("Timeslots"),
          icon: <AccessTimeOutlined />,
          link: "/warehousetimeslots",
        },
      ],
    },
  ];

  const customerMenuItems = [
    {
      id: 1,
      text: t("Dashboard"),
      icon: <InsertChartOutlinedIcon />,
      link: "/dashboard",
    },
    {
      id: 2,
      text: t("Inbound Requests"),
      icon: <SourceOutlinedIcon />,
      link: "/inboundrequests",
    },
    {
      id: 3,
      text: t("Outbound Requests"),
      icon: <SourceOutlinedIcon />,
      link: "/outboundrequests",
    },
    // {
    //   id: 4,
    //   text: t("Putaway Requests"),
    //   icon: <SourceOutlinedIcon />,
    //   link: "/putawayrequests",
    // },
    {
      id: 5,
      text: t("SKUs"),
      icon: <CategoryOutlined />,
      link: "/sku",
    },
    {
      id: 6,
      text: t("SKU Kits"),
      icon: <ShoppingBagOutlined />,
      link: "/skukit",
    },
    {
      id: 7,
      text: t("Customer Addresses"),
      icon: <LocationOnOutlined />,
      link: "/customeraddress",
    },
    {
      id: 8,
      text: t("Reports"),
      icon: <ReportsIcon />,
      links: [
        {
          id: 801,
          text: t("SKU Report"),
          icon: <CategoryOutlined />,
          link: "/skureport",
        },
      ],
    },
    {
      id: 9,
      text: t("Settings"),
      icon: <SettingsApplicationsOutlinedIcon />,
      links: [
        {
          id: 901,
          text: t("Users"),
          icon: <ListAltOutlinedIcon />,
          link: "/users",
        },
      ],
    },
  ];

  const [menuItems, setMenuItems] = useState<IMenuItem[]>(defaultMenuItems);

  useEffect(() => {
    const pathName = location.pathname.substring(0);
    let currentItem: any = null;

    checkAndAddDMSMenuItems();

    menuItems.forEach((i) => {
      if (i.link && i.link === pathName) {
        currentItem = i;
      } else if (i.links && currentItem == null) {
        currentItem = i.links.find((si) => si.link === pathName);
      }
    });

    if (currentItem) setActiveIndex(currentItem.id);

    if (userData.userType == "C") {
      setMenuItems(customerMenuItems);
    } else {
      setMenuItems(defaultMenuItems);
    }
  }, [location.pathname, MODULES, i18n.language]);

  useEffect(() => {
    if (props) setOpen(props?.open);
  }, [props?.open]);

  const checkAndAddDMSMenuItems = () => {
    if (MODULES.includes("DMS")) {
      setMenuItems((oldMenuItems) => {
        const newMenuItems = [...oldMenuItems];

        if (newMenuItems.findIndex((m) => m.id === 4) === -1)
          newMenuItems.push({
            id: 4,
            text: t("Documents"),
            icon: <SourceOutlinedIcon />,
            link: "/documents",
          });

        if (newMenuItems.findIndex((m) => m.id === 5) === -1)
          newMenuItems.push({
            id: 5,
            text: t("Tools"),
            icon: <TuneOutlined />,
            links: [
              {
                id: 5013,
                text: t("Document Templates"),
                icon: <StorageOutlined />,
                link: "/templates",
              },
            ],
          });

        const settingsIndex = newMenuItems.findIndex((m) => m.id === 6);

        if (settingsIndex > -1) {
          if (
            newMenuItems[settingsIndex].links?.findIndex(
              (m) => m.id === 601
            ) === -1
          )
            newMenuItems[settingsIndex].links?.push({
              id: 601,
              text: t("Documents"),
              icon: <SourceOutlinedIcon />,
              links: [
                {
                  id: 6011,
                  text: t("Cabinets"),
                  icon: <StorageOutlined />,
                  link: "/cabinets",
                },
                {
                  id: 6012,
                  text: t("Structure Templates"),
                  icon: <MediationOutlined />,
                  link: "/structuretemplates",
                },
                {
                  id: 6013,
                  text: t("Profiles"),
                  icon: <FolderSharedOutlined />,
                  link: "/documentprofiles",
                },
                {
                  id: 6014,
                  text: t("Types"),
                  icon: <TypeSpecimenOutlined />,
                  link: "/documenttypes",
                },
                {
                  id: 6014,
                  text: t("Categories"),
                  icon: <CategoryOutlined />,
                  link: "/documentcategories",
                },
              ],
            });

          newMenuItems[settingsIndex].links?.sort((m1, m2) => m1.id - m2.id);
        }
        if (newMenuItems.findIndex((m) => m.id === 7) === -1)
          newMenuItems.push({
            id: 7,
            text: t("DMS Reports"),
            icon: <ReportsIcon />,
            link: "/dmsreports",
          });

        newMenuItems.sort((m1, m2) => m1.id - m2.id);

        return newMenuItems;
      });
    }
  };

  return (
    <List
      sx={{
        px: 1,
        flex: "auto",
        overflowY: "auto",
        overflowX: open ? "auto" : "hidden",
      }}
      dir={i18n.dir()}
    >
      {menuItems.map((item: any, index) => {
        if (item.link)
          return (
            <MenuItem
              key={`menuItem_${index}`}
              item={item}
              open={open}
              activeIndex={activeIndex}
              onClick={props.onClick}
            />
          );
        else
          return (
            <SubMenus
              key={`subMenu_${index}`}
              item={item}
              menuIsOpen={open}
              activeIndex={activeIndex}
              onClick={props.onClick}
            />
          );
      })}
    </List>
  );
};

const MenuItem = ({ item, open, activeIndex, onClick }: any) => {
  const activeBgColor = "#F0E5FF";
  const activeTextColor = "#6800F4";
  const defaultBgColor = "transparent";
  const defaultTextColor = "#656E7A";

  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <ListItem
      key={item.text}
      disablePadding
      sx={{
        display: "block",
        borderRadius: "30px",
        my: 2,
        background: item.id === activeIndex ? activeBgColor : defaultBgColor,
        color: item.id === activeIndex ? activeTextColor : defaultTextColor,
      }}
    >
      <ListItemButton
        sx={{
          height: 46,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          color: "inherit",
          borderRadius: "30px",
        }}
        onClick={() => {
          navigate(item.link);
          if (onClick) {
            onClick();
          }
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: i18n.dir() === "ltr" ? (open ? 3 : "auto") : "auto",
            ml: i18n.dir() === "rtl" ? (open ? 3 : "auto") : "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        {open ? (
          <ListItemText
            primary={item.text}
            sx={{
              opacity: open ? 1 : 0,
              textAlign: "initial",
            }}
          />
        ) : null}
      </ListItemButton>
    </ListItem>
  );
};

const SubMenus = ({ item, menuIsOpen, activeIndex }: any) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  useEffect(() => {
    if (!menuIsOpen) setOpenSubMenu(false);
  }, [menuIsOpen]);

  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          color: item.links?.find((i: any) => i.id === activeIndex)
            ? "#6800F4"
            : "#656E7A",
          padding: "8px 20px",
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: i18n.dir() === "ltr" ? (menuIsOpen ? 3 : "auto") : "auto",
            ml: i18n.dir() === "rtl" ? (menuIsOpen ? 3 : "auto") : "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          sx={{
            opacity: menuIsOpen ? 1 : 0,
            textAlign: i18n.dir() === "rtl" ? "right" : "left",
          }}
        />
        {menuIsOpen && (openSubMenu ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      <Collapse in={openSubMenu} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
        <List component="div" disablePadding>
          {item.links.map((item: any, index: number) =>
            item.link ? (
              <MenuItem
                key={`subMenu_menuItem_${index}`}
                item={item}
                index={index}
                open={openSubMenu}
                activeIndex={activeIndex}
              />
            ) : (
              <SubMenus
                key={`subMenu_${index}`}
                item={item}
                menuIsOpen={openSubMenu}
                activeIndex={activeIndex}
              />
            )
          )}
        </List>
      </Collapse>
    </>
  );
};

export default MenuItems;
