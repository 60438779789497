import { PDFDocument, PDFPage, StandardFonts, degrees, rgb } from "pdf-lib";
import { printFile, textToBase64Barcode } from "./globals";

export const createNewPDF = async () => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage();

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

const drawWatermark = (page: PDFPage, text: string) => {
  const { width, height } = page.getSize();
  for (let i = 0; i < height; i += height / 10) {
    for (let j = 10; j < width; j += width / 10) {
      page.drawText(text, {
        x: j,
        y: i,
        size: 10,
        rotate: degrees(-45),
        opacity: 0.25,
      });
    }
  }
};

export const addWatermarkToPDF = async (file: any, text: string) => {
  const pdfDoc = await PDFDocument.load(file);
  const pages = pdfDoc.getPages();
  pages.forEach((value) => drawWatermark(value, text));
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const addImageToPDF = async (
  file: any,
  img: any,
  imgType: any,
  dim: any
) => {
  const pdfDoc = await PDFDocument.load(file);
  const pages = pdfDoc.getPages();
  const image =
    imgType === "png" ? await pdfDoc.embedPng(img) : await pdfDoc.embedJpg(img);
  const dims = image.scale(dim.scale);
  const page = pages[0];
  page.drawImage(image, {
    x: dim.x,
    y: dim.y,
    width: dims.width,
    height: dims.height,
  });
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const addSignatureToPDF = async (
  file: any,
  img: any,
  imgType: any,
  timeStamp: string,
  dim: any
) => {
  const pdfDoc = await PDFDocument.load(file);
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const pages = pdfDoc.getPages();
  const image =
    imgType === "png" ? await pdfDoc.embedPng(img) : await pdfDoc.embedJpg(img);
  const dims = image.scale(dim.scale);
  const page = pages[dim.page - 1];
  console.log(pages, page);
  const { width, height } = page.getSize();
  page.drawImage(image, {
    x: dim.x,
    y: height - dim.y,
    width: dims.width,
    height: dims.height,
  });
  page.drawText(timeStamp, {
    x: dim.x,
    y: height - dim.y - 8,
    size: 8,
    font: timesRomanFont,
    color: rgb(0, 0, 1),
  });
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const addTextToPDF = async (file: any, text: string, dim: any) => {
  const pdfDoc = await PDFDocument.load(file);
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const pages = pdfDoc.getPages();
  const page = pages[0];
  page.drawText(text, {
    x: dim.x,
    y: dim.y,
    size: 14,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
  });
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const mergePDFs = async (pdf1: any, pdf2: any) => {
  const pdfDoc1 = await PDFDocument.load(pdf1);
  const pdfDoc2 = await PDFDocument.load(pdf2);

  const copiedPages = await pdfDoc1.copyPages(
    pdfDoc2,
    pdfDoc2.getPageIndices()
  );
  copiedPages.forEach((page) => {
    pdfDoc1.addPage(page);
  });
  const pdfBytes = await pdfDoc1.save();
  return pdfBytes;
};


export const PrintDocumentWithBarcode = async (
  barcodeText: string
) => {
  let pdf = await createNewPDF();

  const barcode = textToBase64Barcode(barcodeText);
  pdf = await addImageToPDF(pdf, barcode, "png", {
    x: 20,
    y: 760,
    scale: 0.5,
  });
  pdf = await addTextToPDF(pdf, barcodeText, {
    x: 25,
    y: 750,
  });
  const blob = new Blob([pdf], { type: "application/pdf" });
  const link = window.URL.createObjectURL(blob);

  printFile(link);
};
