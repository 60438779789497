import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ListPageDX from "../../components/business/listpagedx";
import { useAuthContext } from "../../context/authcontext";
import { useNotificationContext } from "../../context/notificationcontext";
import useSkuKitService from "../../shared/services/skukitservice";
import SkuKitForm from "../../components/units_forms/sku/skukitform";
import useUserService from "../../shared/services/userservices";
import SelectListDX from "../../components/controls/selectlistdx";
import BoxDX from "../../components/layout/boxdx";
import GridDX from "../../components/layout/griddx";
import SkuKitBulkForm from "../../components/units_forms/sku/skukitbulkform";

const SkuKit = () => {
  const { t, i18n } = useTranslation();
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { userData } = useAuthContext();
  const { getActiveUsers } = useUserService();
  const { setInfo, setError } = useNotificationContext();
  const { getSkuKitsForCustomer, archiveSkuKit } = useSkuKitService();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [customerId, setCustomerId] = useState<any>(null);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [importForm, setImportForm] = useState(false);
  const [SkuKitId, setSkuKitId] = useState<any>(null);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    const id = userData.userType === "C" ? userData.customerId : customerId;
    if (id) {
      setIsLoading(true);
      getSkuKitsForCustomer(id)
        .then((data) => {
          setDataFromApi(data);
          setRows(data);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const getCustomers = async () => {
    getActiveUsers()
      .then((res) => {
        const users = res.filter((user: any) => user.userType === "C");
        setCustomers(
          users.map((user: any) => {
            return { text: user.fullName, value: user.customerId };
          })
        );
      })
      .catch((err) => setError(err));
  };

  const onEdit = (data: any) => {
    setSkuKitId(data.skuKitId);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toImport = () => {
    setImportForm(true);
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveSkuKit(id)
      .then(() => {
        setInfo(t("Sku Kit deleted successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setImportForm(false);
    setSkuKitId(null);
    setShowAddButton(true);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.code?.toLowerCase()?.includes(value.toLowerCase()) ||
        row.name?.toLowerCase()?.includes(value.toLowerCase()) ||
        row.specification?.toLowerCase()?.includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setCustomerId(value);
  };

  useEffect(() => {
    userData.userType === "S" && getCustomers();
  }, []);

  useEffect(() => {
    getData();
  }, [customerId]); 

  useEffect(() => {
    setDataFromApi(rows);
    getData();
    getCustomers();
    setAddRecordfn(() => toCreate);

    return () => {
      setShowAddButton(true);
      setShowTitle(true);
      setAddRecordfn(null);
    };
  }, [i18n.language]);

  return (
    <>
      {showForm && <SkuKitForm id={SkuKitId} handleClose={onClose} />}
      {importForm && <SkuKitBulkForm open={importForm} handleClose={onClose} />}
      {!showForm && (
        <GridDX sx= {{ flexDirection: "column", alignItems: "end", overflow: 'hidden'}} >
        {userData.userType === "S" && (
          <GridDX
            item
            xs={12}
            sx={{
              justifyContent: "end",
              alignItems: "center",
            }}
            mt={1}
          >
            <BoxDX width={350}>
              <SelectListDX
                label={t("Select Customer")}
                name="customerId"
                items={customers}
                value={customerId}
                onChange={handleInputChange}
              />
            </BoxDX>
          </GridDX>
        )}
        <GridDX xs={12}>
          <ListPageDX
            listTitle={t("SKU Kit")}
            name={t("SKU Kit")}
            rows={rows}
            columns={columns}
            getRowId={(row: any) => row.skuKitId}
            isLoading={isLoading}
            onEdit={onEdit}
            onDelete={onDelete}
            editLabel={t("Preview")}
            setGridFilterCriteria={setGridFilterCriteria}
            exportToPDF={false}
            exportToCSV={false}
            showDatePicker={false}
            showSelect={false}
            showImportButton
            onImport={toImport}
            importTemplateLink={"../assets/templates/UnitsSKUTemplate.csv"}
          />
        </GridDX>
      </GridDX>
      )}
    </>
  );
};

export default SkuKit;
