import { useEffect, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import AddEditModalDX from "../../business/addeditmodaldx";
import TextFieldDX from "../../controls/textfielddx";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import moment from "moment";

const SelfCollectionModal = (props: any) => {
  const { open, handleClose, outboundRequestId } = props;
  const { selfCollection } = useOutboundRequestService();
  const defaultValues = {
    outboundRequestId: outboundRequestId,
    noteDate: "",
    noteTypeId: 1,
    pocName: "",
    pocPhone: "",
    identityDocumentTypeId: null,
    identityDocumentNumber: ""
  };
  const [data, setData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const { setError, setInfo } = useNotificationContext();
  const [errors, setErrors] = useState<any>({});
  const [isChanged, setIsChanged] = useState(false);

  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      selfCollection(data)
        .then((res) => {
          setInfo(t("Collected by customer successfully"));
          handleClose(true);
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setIsLoading(false);
          handleClose();
        });
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!data.pocName) {
      newErrors.pocName = t("Name is required");
    }
    if (!data.pocPhone) {
      newErrors.pocPhone = t("Phone is required");
    }
    if (!data.noteDate) {
      newErrors.noteDate = t("Collection Date is required");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: type == "checkbox" ? checked : value,
    });
  };
  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };
  return (
    <AddEditModalDX
      open={open}
      title={t("Collected By Customer")}
      handleClose={handleClose}
      maxWidth="sm"
      isSaving={isLoading}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isChanged={isChanged}
    >
      <GridDX container rowSpacing={3} py={1} columnSpacing={1}>
        <GridDX item xs={12} >
          <UnitsDatePicker
            label={t("Collection Date")}
            name="noteDate"
            value={moment(data.noteDate)}
            onChange={(value: any) =>
              handleDateChange(value, "noteDate")
            }
            minDate={moment()}
            error={errors["noteDate"]}
          />
        </GridDX>


        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Name")}
            name="pocName"
            value={data.pocName}
            onChange={handleInputChange}
            error={errors.pocName}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Phone")}
            name="pocPhone"
            value={data.pocPhone}
            onChange={handleInputChange}
            error={errors.pocPhone}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <SelectListDX
            label={t("ID Document Type")}
            name="identityDocumentTypeId"
            value={data.identityDocumentTypeId}
            onChange={handleInputChange}
            error={errors.identityDocumentTypeId}
            items={[
              { value: "1", text: t("National ID") },
              { value: "2", text: t("Passport") },
            ]}
            InputLabelProps={{
              shrink: data.identityDocumentTypeId !== null, // Set to true if there's a value just to handle label position
            }}

          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("ID Document Number")}
            name="identityDocumentNumber"
            value={data.identityDocumentNumber}
            onChange={handleInputChange}
            error={errors.identityDocumentNumber}
          />
        </GridDX>

      </GridDX>
    </AddEditModalDX>
  );
};

export default SelfCollectionModal;
