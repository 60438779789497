import Autocomplete, { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Chip, TextField } from "@mui/material";
import i18n from "../multilingual/i18n";
import TextFieldDX from "./textfielddx";

const AutoCompleteMultiple = (props: any) => {

  const { t } = useTranslation();

  const { placeholder } = props
  const helperText = props.helperText ?? "";
  const checkValue = props.value ?? "";
  const isError = props.error ?? false;
  const loading = props.loading ?? false;
  const handleChange = (event: any, newValue: any) => {
    props.onChange(newValue); // Pass the selected values to the parent component
  };

  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );
  else if (props.readOnly) {
    return (
      <TextFieldDX
        {...props}
        variant="standard"
        fullWidth
        multiline
        disabled
        placeholder={helperText}
        helperText=""
        InputLabelProps={{ shrink: true }}
        value={checkValue}
      ></TextFieldDX>
    );
  } else
    return (
      <Autocomplete
        {...props}

        sx={
          i18n.dir() === "rtl"
            ? {
              ...props.sx,
              "& .MuiAutocomplete-endAdornment": {
                left: '0 !important',
                right: "auto !important",
              },
              "& .MuiInputBase-root": {
                paddingRight: "10px !important"
              }
            }
            : {}
        }
        onChange={handleChange} // Use the handleChange function
        multiple
        fullWidth
        disableClearable
        options={props.list}
        autoHighlight
        getOptionLabel={(option: any) => option.text}
        isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
        noOptionsText={`${t('No Options')}`}
        renderInput={(params) => {
          const defaultEndAdornment = params.InputProps.endAdornment;

          return (
            <TextFieldDX
              {...params}
              error={isError}
              helperText={props.error ?? helperText}
              label={props.label}
              placeholder={placeholder || ""}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
              variant="outlined"
              disabled={props.readOnly || props.disabled}
              InputLabelProps={{}}
            />
          );
        }}
        renderTags={(value: any, getProps: AutocompleteRenderGetTagProps) => {
          return (
            value.map((item: any, index: number) => <Chip
              label={item.text}
              onDelete={(e: any) => {
                getProps({ index: index }).onDelete(e);
                props.onDeleteTag && props.onDeleteTag(item);
              }}
            />)
          );
        }
        }
      />
    );
};

export default AutoCompleteMultiple;
