import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TypeTranslator = () => {
  const { i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";

  // storage type
  const storageType: any = [];
  // arabic translation should be there
  storageType[1] = { id: 1, en_value: "Chilled", ar_value: "مبرد" };
  storageType[2] = { id: 2, en_value: "Dry", ar_value: "جاف " };
  storageType[3] = { id: 3, en_value: "Frozen", ar_value: "مجمد" };
  storageType[4] = { id: 4, en_value: "Ambient", ar_value: "المحيط" };

  const getStorageTypeValue = (value: number) => {
    const status = storageType[value];

    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const quotationStatuses: any = [];
  quotationStatuses[1] = { id: 1, en_value: "Draft", ar_value: "مسودة" };
  quotationStatuses[2] = {
    id: 2,
    en_value: "Pending",
    ar_value: "قيد الانتظار",
  };
  quotationStatuses[3] = { id: 3, en_value: "Processed", ar_value: "معالج" };

  const getQuotationStatusValue = (value: number) => {
    const status = quotationStatuses[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const leadRequestStatuses: any = [];
  leadRequestStatuses[1] = {
    id: 1,
    en_value: "Pending",
    ar_value: "قيد الانتظار",
  };
  leadRequestStatuses[2] = { id: 2, en_value: "Processed", ar_value: "معالج" };
  leadRequestStatuses[3] = { id: 3, en_value: "Junk", ar_value: "قمامة" };
  leadRequestStatuses[4] = { id: 4, en_value: "Lost", ar_value: "ضائع" };
  const getLeadRequestStatusValue = (value: number) => {
    const status = leadRequestStatuses[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const leadRequestType: any = [];
  leadRequestType[1] = {
    id: 1,
    en_value: "Via Website",
    ar_value: "عبر الموقع الالكتروني",
  };
  leadRequestType[2] = { id: 2, en_value: "Manual", ar_value: "يدوي" };

  const getLeadRequestTypeValue = (value: number) => {
    const status = leadRequestType[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const rentalPeriods: any = [];
  rentalPeriods[1] = { id: 1, en_value: "1 Month", ar_value: "شهر واحد" };
  rentalPeriods[2] = { id: 2, en_value: "3 Months", ar_value: "3 أشهر" };
  rentalPeriods[3] = { id: 3, en_value: "6 Months", ar_value: "6 أشهر" };
  rentalPeriods[4] = { id: 4, en_value: "Annual", ar_value: "سنوي" };

  const getRentalPeriodValue = (value: number) => {
    const status = rentalPeriods[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const inboundDeliveryType: any = [];
  inboundDeliveryType[1] = {
    id: 1,
    en_value: "Customer Drop-off",
    ar_value: "تسليم العميل",
  };
  inboundDeliveryType[2] = {
    id: 2,
    en_value: "Units Pickup",
    ar_value: "استلام الوحدات",
  };
  inboundDeliveryType[3] = {
    id: 3,
    en_value: "Supplier Drop-off",
    ar_value: "تسليم المورد",
  };

  const getInboundDeliveryTypeValue = (value: number) => {
    const status = inboundDeliveryType[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const packagingTypes: any = [];
  packagingTypes[1] = { value: 1, text: languageIsEn ? "Box" : "صندوق" };
  packagingTypes[2] = { value: 2, text: languageIsEn ? "Pallet" : "منصة" };
  packagingTypes[3] = { value: 3, text: languageIsEn ? "Loose" : "مفرق" };
  const getPackagingTypeValue = (value: number) => {
    const status = packagingTypes[value];
    if (status) {
      return status.text;
    } else return value;
  };

  const inboundRequestStatuses: any = [];

  inboundRequestStatuses[1] = { id: 1, en_value: "Draft", ar_value: "مسودة" };
  inboundRequestStatuses[2] = {
    id: 2,
    en_value: "Pending",
    ar_value: "قيد الانتظار",
  };
  inboundRequestStatuses[3] = { id: 3, en_value: "Accepted", ar_value: "قبول" };
  inboundRequestStatuses[4] = { id: 4, en_value: "Declined", ar_value: "رفض" };
  inboundRequestStatuses[5] = { id: 5, en_value: "Modified", ar_value: "معدل" };
  inboundRequestStatuses[6] = {
    id: 6,
    en_value: "Goods Received",
    ar_value: "البضائع المستلمة",
  };
  inboundRequestStatuses[7] = {
    id: 7,
    en_value: "Counting",
    ar_value: "العد",
  };
  inboundRequestStatuses[8] = {
    id: 8,
    en_value: "QC",
    ar_value: "مراقبة الجودة",
  };
  inboundRequestStatuses[9] = {
    id: 9,
    en_value: "Put Away",
    ar_value: "ضع بعيدا",
  };
  inboundRequestStatuses[10] = {
    id: 10,
    en_value: "Completed",
    ar_value: "ضع بعيدا",
  };
  inboundRequestStatuses[11] = {
    id: 11,
    en_value: "Modification Approved",
    ar_value: "تمت الموافقة على التعديل",
  };
  inboundRequestStatuses[12] = {
    id: 12,
    en_value: "Modification Rejected",
    ar_value: "تم رفض التعديل",
  };
  inboundRequestStatuses[13] = {
    id: 13,
    en_value: "Approved",
    ar_value: "موافق",
  };
  inboundRequestStatuses[14] = {
    id: 14,
    en_value: "Rejected",
    ar_value: "اكمال",
  };

  const getInboundRequestStatusValue = (value: number) => {
    const status = inboundRequestStatuses[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const outboundRequestStatuses: any = [];
  outboundRequestStatuses[1] = { id: 1, en_value: "Draft", ar_value: "مسودة" };
  outboundRequestStatuses[2] = {
    id: 2,
    en_value: "Pending",
    ar_value: "قيد الانتظار",
  };
  outboundRequestStatuses[3] = {
    id: 3,
    // showing as Picker Unassigned in the UI (in the system it is accepted)
    en_value: "Picker Unassigned",
    ar_value: "المنتقل غير المعين",
  };
  outboundRequestStatuses[4] = { id: 4, en_value: "Declined", ar_value: "رفض" };
  outboundRequestStatuses[5] = {
    id: 5,
    en_value: "Picker Assigned",
    ar_value: "المنتقل المعين",
  };
  outboundRequestStatuses[6] = {
    id: 6,
    en_value: "Picked",
    ar_value: "اختار",
  };
  outboundRequestStatuses[7] = {
    id: 7,
    en_value: "QC",
    ar_value: "مراقبة الجودة",
  };
  outboundRequestStatuses[8] = {
    id: 8,
    en_value: "Packed",
    ar_value: "معبأ",
  };
  outboundRequestStatuses[9] = {
    id: 9,
    en_value: "Dispatched",
    ar_value: "أرسلت",
  };
  outboundRequestStatuses[10] = {
    id: 10,
    en_value: "Self Collection",
    ar_value: "جمع ذاتي",
  };
  outboundRequestStatuses[11] = {
    id: 11,
    en_value: "Completed",
    ar_value: "اكمال",
  };
  outboundRequestStatuses[12] = {
    id: 12,
    en_value: "Returned",
    ar_value: "عاد",
  };
  outboundRequestStatuses[13] = {
    id: 13,
    en_value: "On Hold",
    ar_value: "في الانتظار",
  };

  outboundRequestStatuses[14] = {
    id: 14,
    en_value: "Cancelled",
    ar_value: "ألغيت",
  };

  const getOutboundRequestStatusValue = (value: number) => {
    const status = outboundRequestStatuses[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  const outboundDeliveryType: any = [];
  outboundDeliveryType[1] = {
    id: 1,
    en_value: "Customer Pickup",
    ar_value: "استلام العميل",
  };
  outboundDeliveryType[2] = {
    id: 2,
    en_value: "Units Delivery",
    ar_value: "تسليم الوحدات",
  };
  outboundDeliveryType[3] = {
    id: 3,
    en_value: "Supplier Pickup",
    ar_value: "استلام المورد",
  };

  const getOutboundDeliveryTypeValue = (value: number) => {
    const status = outboundDeliveryType[value];
    if (status) {
      if (languageIsEn) return status.en_value;
      else return status.ar_value;
    } else return value;
  };

  // warehousetypes Dry, Chilled, Freezed, Ambient
  const warehouseTypes = [
    { value: 1, text: languageIsEn ? "Dry" : "جاف" },
    { value: 2, text: languageIsEn ? "Chilled" : "مبرد" },
    { value: 3, text: languageIsEn ? "Frozen" : "مجمد" },
    { value: 4, text: languageIsEn ? "Ambient" : "المحيط" },
  ];
  const getWarehouseType = (value: number) => {
    const warehouseType = warehouseTypes.find((x) => x.value === value);
    return warehouseType ? warehouseType.text : value;
  };
  const warehouseCategories = [
    { value: 1, text: languageIsEn ? "General" : "عام" },
  ];
  const getWarehouseCategory = (value: number) => {
    const warehouseCategory = warehouseCategories.find(
      (x) => x.value === value
    );
    return warehouseCategory ? warehouseCategory.text : value;
  };
  const rackSizes = [
    { value: 1, text: languageIsEn ? "Small" : "صغير" },
    { value: 2, text: languageIsEn ? "Medium" : "متوسط" },
    { value: 3, text: languageIsEn ? "Large" : "كبير" },
  ];
  const getRackSize = (value: number) => {
    const rackSize = rackSizes.find((x) => x.value === value);
    return rackSize ? rackSize.text : value;
  };
  const locationTypes = [
    { value: 1, text: languageIsEn ? "Shelf" : "صغير" },
    { value: 2, text: languageIsEn ? "Pallet" : "متوسط" },
  ];
  const getLocationType = (value: number) => {
    const locationType = locationTypes.find((x) => x.value === value);
    return locationType ? locationType.text : value;
  };
  const storageModelTypes = [
    { value: 1, text: "Rack" },
    { value: 2, text: "Free Space" },
  ];
  const getStorageModelType = (value: number) => {
    const storageModelType = storageModelTypes.find((x) => x.value === value);
    return storageModelType ? storageModelType.text : value;
  };

  const rowModelTypes = [
    { value: 1, text: "Drive-In" },
    { value: 2, text: "Drive-Thru" },
    { value: 3, text: "Selective" },
    { value: 4, text: "Very Narrow Isle (VNA)" },
    { value: 5, text: "Radio Shuffle" },
    { value: 6, text: "Mobile Rack" },
    { value: 7, text: "Long Span" },
    { value: 8, text: "Mobile Shelf" },
  ];

  const getRowModelType = (value: number) => {
    const rowModelType = rowModelTypes.find((x) => x.value === value);
    return rowModelType ? rowModelType.text : value;
  };

  // 40Feet Container, 20Feet Container, Diyanna, Trailer, Truck
  // with arabic and english translation
  const cargoTypes = [
    { value: 1, text: languageIsEn ? "40Feet Container" : "حاوية 40 قدم" },
    { value: 2, text: languageIsEn ? "20Feet Container" : "حاوية 20 قدم" },
    { value: 3, text: languageIsEn ? "Diyanna" : "ديانا" },
    { value: 4, text: languageIsEn ? "Trailer" : "مقطورة" },
    { value: 5, text: languageIsEn ? "Truck" : "شاحنة" },
  ];

  const unitTypes: any = [];
  // arabic translation should be there
  unitTypes[1] = { value: 1, text: languageIsEn ? "Bit" : "بت" };
  unitTypes[2] = { value: 2, text: languageIsEn ? "Byte" : "بايت" };
  unitTypes[3] = { value: 3, text: languageIsEn ? "Barrel" : "برميل" };
  unitTypes[4] = { value: 4, text: languageIsEn ? "Box" : "تابوت" };
  unitTypes[5] = { value: 5, text: languageIsEn ? "Celsius" : "مئويه" };
  unitTypes[6] = {
    value: 6,
    text: languageIsEn ? "Cubic Centimeter" : "سنتيمتر مكعب",
  };
  unitTypes[7] = { value: 7, text: languageIsEn ? "Centimeter" : "سنتيمتر" };
  unitTypes[8] = { value: 8, text: languageIsEn ? "Cubic" : "مكعب" };
  unitTypes[9] = { value: 9, text: languageIsEn ? "Dozen" : "دزينة" };
  unitTypes[10] = { value: 10, text: languageIsEn ? "Fahrenheit" : "فهرنهايت" };
  unitTypes[11] = {
    value: 11,
    text: languageIsEn ? "Fluid Ounce" : "أونصة سائلة",
  };
  unitTypes[12] = { value: 12, text: languageIsEn ? "Foot" : "قدم" };
  unitTypes[13] = { value: 13, text: languageIsEn ? "Grams" : "غرام" };
  unitTypes[14] = { value: 14, text: languageIsEn ? "Gallon" : "غالون" };
  unitTypes[15] = { value: 15, text: languageIsEn ? "Gigabyte" : "غيغا بايت" };
  unitTypes[16] = { value: 16, text: languageIsEn ? "Grain" : "حب" };
  unitTypes[17] = { value: 17, text: languageIsEn ? "Gross" : "الاجمالي" };
  unitTypes[18] = { value: 18, text: languageIsEn ? "Hectare" : "الهكتار" };
  unitTypes[19] = { value: 19, text: languageIsEn ? "Inch" : "بوصه" };
  unitTypes[20] = { value: 20, text: languageIsEn ? "Kelvin" : "كيلفن" };
  unitTypes[21] = { value: 21, text: languageIsEn ? "Knot" : "عقدة" };
  unitTypes[22] = { value: 22, text: languageIsEn ? "Kilobyte" : "كيلوبايت" };
  unitTypes[23] = { value: 23, text: languageIsEn ? "Kiloliter" : "كيلولتر" };
  unitTypes[24] = { value: 24, text: languageIsEn ? "Kilometer" : "كيلومتر" };
  unitTypes[25] = { value: 25, text: languageIsEn ? "Karat" : "قيراط" };
  unitTypes[26] = { value: 27, text: languageIsEn ? "Kilowatt" : "كيلووات" };
  unitTypes[28] = {
    value: 28,
    text: languageIsEn ? "Kilowatt-hour" : "كيلوواط ساعة",
  };
  unitTypes[29] = { value: 29, text: languageIsEn ? "Liter" : "لتر" };
  unitTypes[30] = { value: 30, text: languageIsEn ? "Pound" : "جنيه" };
  unitTypes[31] = { value: 31, text: languageIsEn ? "Long Ton" : "لونغ تون" };
  unitTypes[32] = { value: 32, text: languageIsEn ? "Meter" : "متر" };
  unitTypes[33] = { value: 33, text: languageIsEn ? "Megabyte" : "ميغا بايت" };
  unitTypes[34] = { value: 34, text: languageIsEn ? "Microgram" : "ميكروجرام" };
  unitTypes[35] = { value: 35, text: languageIsEn ? "Mile" : "ميل" };
  unitTypes[36] = { value: 36, text: languageIsEn ? "Milliliter" : "مليلتر" };
  unitTypes[37] = {
    value: 37,
    text: languageIsEn ? "Miles Per Hour" : "ميل في الساعة",
  };
  unitTypes[38] = { value: 38, text: languageIsEn ? "Metric Ton" : "طن متري" };
  unitTypes[39] = {
    value: 39,
    text: languageIsEn ? "Nautical Mile" : "ناوتيكال مايل",
  };
  unitTypes[40] = { value: 40, text: languageIsEn ? "Pallet" : "البالته" };
  unitTypes[41] = { value: 41, text: languageIsEn ? "Pcs" : "القطع" };
  unitTypes[42] = { value: 42, text: languageIsEn ? "Pint" : "Pint" };
  unitTypes[43] = { value: 43, text: languageIsEn ? "Quart" : "الكوارت" };
  unitTypes[44] = {
    value: 44,
    text: languageIsEn ? "Revolutions Per Minute" : "الثورات في الدقيقة",
  };
  unitTypes[45] = { value: 45, text: languageIsEn ? "Square" : "مربع" };
  unitTypes[46] = {
    value: 46,
    text: languageIsEn ? "Tablespoon" : "ملعقه كبيره",
  };
  unitTypes[47] = { value: 47, text: languageIsEn ? "Ton" : "طن" };
  unitTypes[48] = {
    value: 48,
    text: languageIsEn ? "Teaspoon" : "ملعقه صغيره",
  };
  unitTypes[49] = { value: 49, text: languageIsEn ? "Watt" : "واط" };
  unitTypes[50] = { value: 50, text: languageIsEn ? "Yard" : "ساحة" };

  const storageTypes: any = [];
  // arabic translation should be there
  storageTypes[1] = { value: 1, text: languageIsEn ? "Standard" : "القياسيه" };

  const mappingTypes: any = [];
  // arabic translation should be there
  mappingTypes[1] = {
    value: 1,
    text: languageIsEn ? "Secondary UOM" : "ثانوي",
  };
  mappingTypes[2] = { value: 2, text: languageIsEn ? "Supplier SKU" : "مورد" };
  mappingTypes[3] = {
    value: 3,
    text: languageIsEn ? "Alternate Barcode" : "الباركود البديل",
  };
  mappingTypes[4] = {
    value: 4,
    text: languageIsEn ? "Product ID" : "معرف المنتج",
  };

  const integrationMethodTypes: any = [];
  // arabic translation should be there
  integrationMethodTypes[1] = {
    value: 1,
    text: languageIsEn ? "Not Integrated" : "غير متكامل",
  };
  integrationMethodTypes[2] = {
    value: 2,
    text: languageIsEn
      ? "Marketplace Integrated Carrier"
      : "سوق الناقل المتكامل",
  };
  integrationMethodTypes[3] = {
    value: 3,
    text: languageIsEn ? "WMS Integrated Carrier" : "WMS الناقل المتكامل",
  };

  const outboundDeliveryTypes = [
    { value: 1, text: languageIsEn ? "Customer Pickup" : "استلام العميل" },
    { value: 2, text: languageIsEn ? "Units Drop-off" : "تسليم الوحدات" },
    { value: 3, text: languageIsEn ? "Supplier Pickup" : "استلام المورد" },
  ];
  const getOutboundDeliveryType = (value: number) => {
    const outboundDeliveryType = outboundDeliveryTypes.find(
      (x) => x.value === value
    );
    return outboundDeliveryType ? outboundDeliveryType.text : value;
  };

  const vehicleTypes = [
    { value: 1, text: languageIsEn ? "Sedan" : "سيدان" },
    { value: 2, text: languageIsEn ? "SUV" : "سيارة دفع رباعي" },
    { value: 3, text: languageIsEn ? "Truck" : "شاحنة" },
    { value: 4, text: languageIsEn ? "Van" : "فان" },
  ];
  const getVehicleType = (value: number) => {
    const vehicleType = vehicleTypes.find((x) => x.value === value);
    return vehicleType ? vehicleType.text : value;
  };
  return {
    getStorageTypeValue,
    getQuotationStatusValue,
    quotationStatuses,
    getLeadRequestStatusValue,
    leadRequestStatuses,
    getLeadRequestTypeValue,
    getRentalPeriodValue,
    getInboundDeliveryTypeValue,
    getPackagingTypeValue,
    packagingTypes,
    getInboundRequestStatusValue,
    inboundRequestStatuses,
    getOutboundRequestStatusValue,
    outboundRequestStatuses,
    getOutboundDeliveryTypeValue,
    warehouseTypes,
    getWarehouseType,
    warehouseCategories,
    getWarehouseCategory,
    rackSizes,
    getRackSize,
    locationTypes,
    getLocationType,
    storageModelTypes,
    getStorageModelType,
    rowModelTypes,
    getRowModelType,
    cargoTypes,
    unitTypes,
    storageTypes,
    mappingTypes,
    integrationMethodTypes,
    outboundDeliveryTypes,
    getOutboundDeliveryType,
    vehicleTypes,
    getVehicleType,
  };
};

export default TypeTranslator;
