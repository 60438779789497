import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useNavigate } from "react-router-dom";
import ListPageDX from "../../components/business/listpagedx";
import { useNotificationContext } from "../../context/notificationcontext";
import useWarehouseOrderBinService from "../../shared/services/warehouseorderbinservice";
import GridDX from "../../components/layout/griddx";
import BoxDX from "../../components/layout/boxdx";
import SelectListDX from "../../components/controls/selectlistdx";
import useWarehouseService from "../../shared/services/warehouseservice";
import OrderBinBulkForm from "../../components/units_forms/orderbins/orderbinsbulkform";
import { printFile } from "../../shared/globals";
import { DocumentScannerOutlined } from "@mui/icons-material";
import { PrintDocumentWithBarcode } from "../../shared/pdfoperations";

const OrderBins = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setAddRecordfn } = useOutletContext() as any;
  const { setInfo, setError } = useNotificationContext();
  const { getWarehouses } = useWarehouseService();
  const { getWarehouseOrderBinsByWarehouseId, archiveWarehouseOrderBin } = useWarehouseOrderBinService();
  const navigate = useNavigate();

  const [rows, setRows] = useState<any>([]);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [warehouseId, setWarehouseId] = useState<any>(null);
  const [warehouses, setWarehouses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [importForm, setImportForm] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: `${t("Bin Code")}`,
      flex: 1,
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      minWidth: 200,
      valueFormatter(params){
        return (params.value === 1 ? t("Unallocated") : t("Allocated"))
      },
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: "isFill",
      headerName: `${t("Filled")}`,
      minWidth: 200,
      valueFormatter(params) {
          return (params.value ? t('Yes') : t("No"))
      },
      headerAlign: 'center',
      align: 'center'
    },
  ];

  useEffect(() => {
    getData();
  }, [warehouseId]); 

  useEffect(() => {
    getData();
    getWarehouseList();
    setAddRecordfn(() => toCreate);
  }, [i18n.language]);

  useEffect(() => {
    getWarehouseList();
  }, []); 

  const getData = () => {
    if (warehouseId) {
      setIsLoading(false);
      getWarehouseOrderBinsByWarehouseId(warehouseId)
        .then((data) => {
          setDataFromApi(data);
          setRows(data);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    };

  const getWarehouseList = async () => {
    getWarehouses()
      .then((res) => {
        setWarehouses(
          res.map((item: any) => {
            return { text: item.name, value: item.warehouseId };
          })
        );
      })
      .catch((err) => setError(err));
  };

  const toCreate = () => {
    navigate("/warehouseorderbindetails", {
      state: {},
    });
  };

  const onDelete = (id: number) => {
    setIsLoading(true);
    archiveWarehouseOrderBin(id)
      .then(() => {
        setInfo(t("Bin has been archived successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  
  const toImport = () => {
    setImportForm(true);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      (row: any) =>
        row.name.toLowerCase().includes(value.toLowerCase()) ||
        row.startTime.toLowerCase().includes(value.toLowerCase()) ||
        row.endTime.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setWarehouseId(value);
  };

  const onClose = (refreshPage: boolean = false) => {
    setImportForm(false);

    if (refreshPage) getData();
  };

  return (
    <GridDX sx= {{ flexDirection: "column", alignItems: "flex-end", overflow: 'hidden'}} >
      {importForm && <OrderBinBulkForm open={importForm} handleClose={onClose} />}
      <GridDX
        item
        xs={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        mt={1}
      >
        <BoxDX width={350}>
          <SelectListDX
            label={t("Select Warehouse")}
            name="warehouseId"
            items={warehouses}
            value={warehouseId}
            onChange={handleInputChange}
          />
        </BoxDX>
      </GridDX>
      <ListPageDX
        listTitle={t("WarehouseOrderBins")}
        name={t("Warehouse Order Bins")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.warehouseOrderBinId}
        isLoading={isLoading}
        onDelete={onDelete}
        deleteLabel={t("Archive")}
        editLabel={t("Preview")}
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
        showDatePicker={false}
        showSelect={false}
        showImportButton
        onImport={toImport}
        importTemplateLink={"../assets/templates/UnitsOrderBinTemplate.csv"}
        onPrint={(data: any) => PrintDocumentWithBarcode(data.code)}
      />
    </GridDX>
  );
};

export default OrderBins;
