import { t } from "i18next";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import usePackingMaterialService from "../../../shared/services/packingmaterialservice";
import useTenantService from "../../../shared/services/tenantservice";
import TypeTranslator from "../../../shared/typetranslator";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../layout/loading";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";

const PackingMaterialForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const packageData = state?.packageData;
  const handleClose = () => {
    navigate(-1);
  };
  const { setInfo, setError } = useNotificationContext();
  const { getPackingMaterialById, addPackingMaterial, updatePackingMaterial } =
    usePackingMaterialService();
  const { getTenants } = useTenantService();
  const { unitTypes } = TypeTranslator();

  const defaultData = {
    name: "",
    sku: "",
    unitTypeId: "",
    weightInGm: 0,
    height: 0,
    width: 0,
    length: 0,
    numOfUnits: 0,
    thresholdLimit: 0,
  };

  const [data, setData] = useState<any>(defaultData);
  const [tenants, setTenants] = useState<any>([]);
  const [chargeablesFromApi, setChargeablesFromApi] = useState<any>([]);
  const [nonChargeablesFromApi, setNonChargeablesFromApi] = useState<any>([]);
  const [deleteChargeables, setDeleteChargeables] = useState<any>([]);
  const [chargeables, setChargeables] = useState<any>([]);
  const [unChargeables, setUnChargeables] = useState<any>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChargeablesChange = (value: any) => {
    setIsChanged(true);
    setChargeables(value);
  };

  const handleUnChargeablesChange = (value: any) => {
    setIsChanged(true);
    setUnChargeables(value);
  };

  const handleTagDelete = (value: any) => {
    setDeleteChargeables([...deleteChargeables, value])
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.name) newErrors["name"] = "Name is required";
    if (!data.sku) newErrors["sku"] = "Sku is required";
    if (data.unitTypeId === "") newErrors["unitTypeId"] = "Unit Type is required";
    if (data.weightInGm <= 0) newErrors["weightInGm"] = "Must be greater than 0";
    if (data.height <= 0) newErrors["height"] = "Must be greater than 0";
    if (data.width <= 0) newErrors["width"] = "Must be greater than 0";
    if (data.length <= 0) newErrors["length"] = "Must be greater than 0";
    if (data.numOfUnits <= 0) newErrors["numOfUnits"] = "Must be greater than 0";
    
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsSaving(true);

      let packingChargeables: any = [];
      
      const addedChargeables = chargeables.filter((item: any) => !chargeablesFromApi.some((obj: any) => (item.value === obj.tenantId)));
      const deletedChargeables = chargeablesFromApi.filter((obj: any) => !chargeables.some((item: any) => (item.value === obj.tenantId)));

      const addedNonChargeables = unChargeables.filter((item: any) => !nonChargeablesFromApi.some((obj: any) => (item.value === obj.tenantId)));
      const deletedNonChargeables = nonChargeablesFromApi.filter((obj: any) => !unChargeables.some((item: any) => (item.value === obj.tenantId)));

      addedChargeables.forEach((item: any) => packingChargeables.push({
        tenantId: item.value,
        isChargeable: true
      }));
      deletedChargeables.forEach((item: any) => packingChargeables.push({...item, isDeleted: true}));
      addedNonChargeables.forEach((item: any) => packingChargeables.push({
        tenantId: item.value,
        isChargeable: false
      }));
      deletedNonChargeables.forEach((item: any) => packingChargeables.push({...item, isDeleted: true}));
      
      const saveData = {
        ...data,
        packingChargeables: packingChargeables
      };

      console.log(saveData);
      
      const operation = packageData
        ? updatePackingMaterial(saveData.PackingMaterialId, saveData)
        : addPackingMaterial(saveData);
      operation
        .then(() => {
          setInfo(
            packageData
              ? t("Packing Material updated successfully")
              : t("Packing Material added successfully")
          );
          handleClose();
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  };

  const getData = async () => {
    setIsLoading(true);
    getTenants()
      .then((res) => {
        setTenants(res.map((tenant: any) => ({
          text: tenant.name,
          value: tenant.tenantId,
        })));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (packageData) {
      getPackingMaterialById(packageData.packingMaterialId)
      .then((res) => {
        setData(res);
        let chargeableTenants: any = [];
        let unChargeableTenants: any = [];
        let chargeableTenantsfromApi: any = [];
        let unChargeableTenantsfromApi: any = [];
        res.packingChargeables.forEach((tenant: any, index: any) => {
          if (tenant.isChargeable) {
            chargeableTenants.push({text: tenant.tenantName, value: tenant.tenantId});
            chargeableTenantsfromApi.push(tenant);
          }
          else {
            unChargeableTenants.push({text: tenant.tenantName, value: tenant.tenantId});
            unChargeableTenantsfromApi.push(tenant);
          }
        setChargeables([...chargeableTenants]);
        setUnChargeables([...unChargeableTenants])
        setChargeablesFromApi([...chargeableTenantsfromApi]);
        setNonChargeablesFromApi([...unChargeableTenantsfromApi]);
        });
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
    };
    getData();
  }, []);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("Packing Material Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      {isLoading && <Loading sx={{ width: "100%", height: "100%" }}/>}
      <GridDX
        container
        sx={{
          p: 2,
        }}
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("SKU")}
            name="sku"
            value={data?.sku}
            onChange={handleInputChange}
            error={errors["sku"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Material Weight")} (g)`}
            name="weightInGm"
            value={data?.weightInGm}
            onChange={handleInputChange}
            type="number"
            error={errors["weightInGm"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              label={t("Unit")}
              items={unitTypes}
              name="unitTypeId"
              value={data.unitTypeId}
              onChange={handleInputChange}
              error={errors["unitTypeId"]}
            />
          </GridDX>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Height")} ${t("Unit")}`}
            name="height"
            value={data?.height}
            onChange={handleInputChange}
            type="number"
            error={errors["height"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Width")} ${t("Unit")}`}
            name="width"
            value={data?.width}
            onChange={handleInputChange}
            type="number"
            error={errors["width"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Length")} ${t("Unit")}`}
            name="length"
            value={data?.length}
            onChange={handleInputChange}
            type="number"
            error={errors["length"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("No. Of Units")}
            name="numOfUnits"
            value={data?.numOfUnits}
            onChange={handleInputChange}
            type="number"
            error={errors["numOfUnits"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Threshold Limit")}
            name="thresholdLimit"
            value={data?.thresholdLimit}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <AutoCompleteMultiple
            label={t("Chargeable Companies")}
            list={tenants}
            value={chargeables || []}
            onChange={handleChargeablesChange}
            onDeleteTag={handleTagDelete}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <AutoCompleteMultiple
            label={t("Non-Chargeable Companies")}
            list={tenants}
            value={unChargeables || []}
            onChange={handleUnChargeablesChange}
            onDeleteTag={handleTagDelete}
          />
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default PackingMaterialForm;
