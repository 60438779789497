import useSecureAPI from "../hooks/usesecureapi";

const useTenantService = () => {
  const secureAPI = useSecureAPI();

  const getTenants = async () => {
    const response = await secureAPI.get("Tenant");
    return response.data;
  };

  const getTenantById = async (id: number) => {
    const response = await secureAPI.get(`Tenant/${id}`);
    return response.data;
  };

  const archiveTenant = async (id: number) => {
    const response = await secureAPI.put(`Tenant/archive/${id}`);
    return response.data;
  };

  const addTenant = async (data: any) => {
    const response = await secureAPI.post("Tenant", data);
    return response.data;
  }

  const updateTenant = async (data: any) => {
    const response = await secureAPI.put(`Tenant`, data);
    return response.data
  }

  const deleteTenantLogo = async (id: number) => {
      const response = await secureAPI.put(`Tenant/deletelogo/${id}`);
      return response.data;
    };

  return {
    getTenants,
    getTenantById,
    archiveTenant,
    addTenant,
    updateTenant,
    deleteTenantLogo
  };
};

export default useTenantService;
