import { t } from "i18next";
import ButtonDX from "../../../controls/buttondx";
import BoxDX from "../../../layout/boxdx";
import DataGridDX from "../../../layout/datagriddx";
import { Edit, Archive, Print } from "@mui/icons-material";
import { GridColDef, GridRowParams, GridActionsCellItem } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import TypeTranslator from "../../../../shared/typetranslator";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../../context/notificationcontext";
import useWarehouseRackLocationService from "../../../../shared/services/warehouseracklocationservice";
import ActionDropDownMenuDx from "../../../controls/dropdownmenudx";
import WarehouseRackLocationBulkForm from "../../../units_forms/warehouse/warehouselocationbulkform";
import { PrintDocumentWithBarcode } from "../../../../shared/pdfoperations";

const LocationList = (props: any) => {
  const {warehouseId} = props;
  const navigate = useNavigate();
  const { setInfo, setError } = useNotificationContext();
  const { getWarehouseRackLocationsByWarehouseId, archiveWarehouseRackLocation } =
    useWarehouseRackLocationService();
  const { getWarehouseCategory, getRackSize, getLocationType } = TypeTranslator();

  const [warehouseLocations, setWarehouseLocations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [importForm, setImportForm] = useState(false);

  const locationColumns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    { field: "code",
      headerName: `${t("Code")}`,
      maxWidth: 150,
    },
    { field: "rackName",
      headerName: `${t("Rack")}`,
      maxWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "locationDetailTypeId",
      headerName: `${t("Shelf/Pallet")}`,
      minWidth: 150,
      valueGetter(params) {
          return getLocationType(params.row.locationDetailTypeId);
      },
      align: 'center',
      headerAlign: 'center',
    },
    // {
    //   field: "warehouseCategoryTypeId",
    //   headerName: `${t("Category")}`,
    //   minWidth: 150,
    //   valueGetter(params) {
    //       return getWarehouseCategory(params.row.warehouseCategoryTypeId);
    //   },
    //   align: 'center',
    //   headerAlign: 'center',
    // },
    {
      field: "rackSizeTypeId",
      headerName: `${t("Size")}`,
      minWidth: 150,
      valueGetter(params) {
          return getRackSize(params.row.rackSizeTypeId);
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "isActive",
      headerName: `${t("Status")}`,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <div
            style={{
              backgroundColor: params.value ? "#E8F5E9" : "#FFEBEE",
              color: params.value ? "#4CAF50" : "#F44336",
              padding: "5px",
              margin: "5px",
              borderRadius: "4px",
              width: 90,
              textAlign: "center",
            }}
          >
            {params.value ? "Active" : "Not Active"}
          </div>
        );
      },
    },
    {
      field: "heightInCm",
      headerName: `${t("Height")}`,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "widthInCm",
      headerName: `${t("Width")}`,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "lengthInCm",
      headerName: `${t("Length")}`,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Print Label")}
            showInMenu
            onClick={() => {
              PrintDocumentWithBarcode(params.row.code);
            }}
            icon={<Print />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Archive")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const onEdit = (data: any) => {
    navigate("/warehouseracklocationdetails", {
      state: {
        warehouseId: warehouseId,
        warehouseRackLocationId: data.id,
      },
    });
  };

  const toCreate = () => {
    navigate("/warehouseracklocationdetails", {
      state: {
        warehouseId: warehouseId,
      },
    });
  };

  const onArchive = (data: any) => {
    setIsLoading(true);
    archiveWarehouseRackLocation(data.warehouseLocationId)
      .then(() => {
        setInfo(t("Warehouse Location archived successfully"));
        getData();
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  
  const onImport = () => {
    setImportForm(true);
  };

  const onClose = (refreshPage: boolean = false) => {
    setImportForm(false);

    if (refreshPage) getData();
  };

  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.href = "";
    link.setAttribute("download", `UNITsTemplate.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const getData = () => {
    if (warehouseId) {
      setIsLoading(true);
      getWarehouseRackLocationsByWarehouseId(warehouseId)
        .then((locations) => {
          setWarehouseLocations(locations);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };
  
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {importForm && <WarehouseRackLocationBulkForm open={importForm} handleClose={onClose} warehouseId={warehouseId}/>}
      <BoxDX
        sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mb: 2 }}
      >
        <ActionDropDownMenuDx
          label={t("Import")}
          actions={[
            { label: t("Import Data"), onClick: onImport },
            {
              label: t("Download Template"),
              onClick: downloadTemplate,
            },
          ]}
          sx={{ mx: 2 }}
        />
        <ButtonDX
          variant="contained"
          color="primary"
          onClick={toCreate}
          sx={{ maxWidth: 150 }}
        >
          {t("Add Location")}
        </ButtonDX>
      </BoxDX>

      <DataGridDX
        getRowId={(row: any) => row.warehouseRackLocationId}
        rows={warehouseLocations}
        columns={locationColumns}
        loading={isLoading}
        disableRowSelectionOnClick={true}
        checkboxSelection={false}
        onRowClick={onEdit}
      />
    </>
  );
};

export default LocationList;