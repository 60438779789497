import useSecureAPI from "../hooks/usesecureapi";

const UseIndustryTypeService = () => {
  const secureAPI = useSecureAPI();

  const getIndustryTypes = async () => {
    const response = await secureAPI.get("IndustryType");
    return response.data;
  };


  const getIndustryTypeById = async (id: number) => {
    const response = await secureAPI.get(`IndustryType/${id}`);
    return response.data;
  };

  return {
    getIndustryTypes,
    getIndustryTypeById
  };
};

export default UseIndustryTypeService;