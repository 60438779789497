import useSecureAPI from "../hooks/usesecureapi";

const useDashboardService = () => {
  const secureAPI = useSecureAPI();

  const getOrderCount = async (startDate: string | null , endDate: string | null) => {
    const response = await secureAPI.get(`Dashboard/orderCount/${startDate}/${endDate}`);
    return response.data;
  };

  const getCustomerCount = async (startDate: string | null , endDate: string | null) => {
    const response = await secureAPI.get(`Dashboard/customerCount/${startDate}/${endDate}`);
    return response.data;
  };

  const getGoodsSummary = async () => {
    const response = await secureAPI.get(`Dashboard/goodsSummary`);
    return response.data;
  };

  const getStorageSummary = async () => {
    const response = await secureAPI.get(`Dashboard/storageSummary`);
    return response.data;
  };

  const getExpiringItemsCount = async () => {
    const response = await secureAPI.get(`Dashboard/expiringItemsCount`);
    return response.data;
  };

  const getExpiringContracts = async () => {
    const response = await secureAPI.get(`Dashboard/expiringContracts`);
    return response.data;
  };
  const getNearyExpirySkus = async () => {
    const response = await secureAPI.get(`Dashboard/nearyexpiryskus`);
    return response.data;
  };
  const getLowStockSkus = async () => {
    const response = await secureAPI.get(`Dashboard/lowstockskus`);
    return response.data;
  };
  const getInboundSummaryByStatus = async (startDate: string | null , endDate: string | null) => {
    if (!startDate || !endDate) {
      return [];
    }
    const response = await secureAPI.get(`Dashboard/inboundbystatus/${startDate}/${endDate}`);
    return response.data;
  };

  const getOutboundSummaryByStatus = async (startDate: string | null , endDate: string | null) => {
    if (!startDate || !endDate) {
      return [];
    }
    const response = await secureAPI.get(`Dashboard/outboundbystatus/${startDate}/${endDate}`);
    return response.data;
  };

  const getOverallWarehousesCapacity = async () => {
    const response = await secureAPI.get(`Dashboard/warehousescapacity`);
    return response.data;
  };

  const getWarehouseCapacity = async (id: number) => {
    const response = await secureAPI.get(`Dashboard/warehousecapacity/${id}`);
    return response.data;
  };


  return {
    getOrderCount,
    getCustomerCount,
    getGoodsSummary,
    getStorageSummary,
    getExpiringItemsCount,
    getExpiringContracts,
    getNearyExpirySkus,
    getLowStockSkus,
    getInboundSummaryByStatus,
    getOutboundSummaryByStatus,
    getOverallWarehousesCapacity,
    getWarehouseCapacity
  };
};

export default useDashboardService;
