import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import BoxDX from "../../layout/boxdx";
import GridDX from "../../layout/griddx";
import TypographyDX from "../../layout/typographydx";
import ButtonDX from "../../controls/buttondx";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { read, utils } from "xlsx";
import { useNotificationContext } from "../../../context/notificationcontext";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import TextFieldDX from "../../controls/textfielddx";
import useWarehouseAreaService from "../../../shared/services/warehouseareaservice";
import TypeTranslator from "../../../shared/typetranslator";

const WarehouseAreaBulkForm = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const { addWarehouseAreasInBulk } = useWarehouseAreaService();
  const { warehouseCategories } = TypeTranslator();

  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<null | File>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState("");

  const handleClick = (event: any) => {
    if (inputRef.current != null) {
      inputRef.current.click();
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    if (event.target.files) setFile(event.target.files[0]);
  };

  const handleDrop = async (event: any) => {
    event.preventDefault();
    if (event.target.files) setFile(event.target.files[0]);
  };

  const onChangeHandler = async (event: any) => {
    if (event.target.files) setFile(event.target.files[0]);
  };

  const onSave = async () => {
    if (!file) {
      setError(t("Please select a file"));
      return;
    }

    setIsSaving(true);
    const ab = await file?.arrayBuffer();
    const wb = read(ab); //parse data
    const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    const readData = utils.sheet_to_json(ws); // generate objects
    let data: any = []; 
    readData.forEach((item: any) => {
      const { Category, ...rest } = item;
      const id = getCategoryId(Category);
      if(id !== null) {
          data.push({
            ...rest,
            warehouseCategoryTypeId: id,
            warehouseId: props.warehouseId,
            usedCBM: 0,
          });
      }
      else {
        setErrors("One or more rows had unknown categories");
      }
    });

    const operation = addWarehouseAreasInBulk(data);

    operation
      .then((res) => {
        setInfo(t("Warehouse Areas added successfully"));
        if (errors === "") props.handleClose(true);
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const getCategoryId = (name: string) => {
    for (let category of warehouseCategories) {
      if (category.text.toLowerCase() === name.toLowerCase()) return category.value;
    }
    return null
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleClose()}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
      sx={{ direction: i18n.dir(), ...props.sx }}
    >
      <DialogTitle justifyContent={"space-between"}>
        <TypographyDX variant="h5" fontWeight="bold">
          {t("Warehouse Areas Import")}
        </TypographyDX>
      </DialogTitle>
      <DialogContent>
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ py: 1 }}>
          {errors === "" ? (
            <>
              <GridDX
                item
                xs={12}
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ mx: 2, height: "100%" }}
              >
                {file ? (
                  <BoxDX display="flex" sx={{ width: 600 }}>
                    <Chip
                      label={file.name}
                      color="primary"
                      onDelete={() => setFile(null)}
                    />
                  </BoxDX>
                ) : (
                  <BoxDX
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    component="fieldset"
                    sx={{
                      border: "1px dashed #c6c6c6",
                      borderRadius: 1,
                      width: 600,
                      height: 150,
                    }}
                  >
                    <BoxDX
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Typography
                        sx={{
                          fontSize: 18,
                          textAlign: "center",
                        }}
                      >
                        {t("Drag and Drop File here or")}
                      </Typography>
                      <ButtonDX variant="text" onClick={handleClick}>
                        {t("Browse")}
                      </ButtonDX>
                    </BoxDX>
                    <BoxDX sx={{ display: "none" }} justifyContent="center">
                      <input
                        ref={inputRef}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        type="file"
                        name="file"
                        style={{
                          display: "none",
                        }}
                        onChange={onChangeHandler}
                      />
                    </BoxDX>
                  </BoxDX>
                )}
              </GridDX>
              </>)
            : (
            <GridDX
              item
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: 2, height: "100%" }}
            >
              <BoxDX
                display="flex"
                sx={{ width: 600, justifyContent: "space-between" }}
              >
                <TextFieldDX
                  name="errors"
                  label={t("Errors")}
                  value={errors}
                  multiline
                  readOnly
                />
              </BoxDX>
            </GridDX>
            
          )}
        </GridDX>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <BoxDX display="flex" flexDirection="row">
          {errors === "" ? (
            <>
              <ButtonDX
                variant="outlined"
                sx={{ mx: 2, mb: { xs: 2, sm: "auto" }, width: 120 }}
                onClick={() => props.handleClose()}
              >
                {t("Cancel")}
              </ButtonDX>
              <LoadingButtonDX
                onClick={onSave}
                sx={{ width: 120 }}
                loading={isSaving}
                loadingPosition="end"
              >
                {t("Save")}
              </LoadingButtonDX>
            </>
          ) : (
            <ButtonDX
              disabled={isSaving}
              onClick={() => props.handleClose(true)}
            >
              {t("Close")}
            </ButtonDX>
          )}
        </BoxDX>
      </DialogActions>
    </Dialog>
  );
};

export default WarehouseAreaBulkForm;
